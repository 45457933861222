export function phone(value: string) {

    if (value === null || value === undefined) return value

    value = value.replace(/\D/g, '').substr(0, 11)
    let length = value.length

    if (length === 0) return value
    if (length === 1 && value !== '8') {
        value = '8' + value
        length++
    }

    const map = {
        1: '8',
        2: '8 ($2',
        3: '8 ($2$3',
        4: '8 ($2$3$4',
        5: '8 ($2$3$4) $5',
        6: '8 ($2$3$4) $5$6',
        7: '8 ($2$3$4) $5$6$7',
        8: '8 ($2$3$4) $5$6$7-$8',
        9: '8 ($2$3$4) $5$6$7-$8$9',
        10: '8 ($2$3$4) $5$6$7-$8$9-$10',
        11: '8 ($2$3$4) $5$6$7-$8$9-$10$11',
    } as any

    const regexp = '(\\d)'.repeat(length)

    return value.replace(new RegExp(regexp, 'g'), map[length])
}

export function phone_code(value: string) {
    return value.replace(/\D/g, '').substr(0, 6)
}

export const INVOICE_AMOUNT_MSG = 'Число должно быть от 0 до 9 999 999'

export function invoice_amount(value: string) {
    return (value + '').replace(/\D/g, '').substr(0, 7)
}

export function passport_series(value: string) {
    return value.replace(/\D/g, '').substr(0, 4)
}

export function passport_number(value: string) {
    return value.replace(/\D/g, '').substr(0, 6)
}

export const FULL_NAME_MSG = 'Доступна только кириллица'

export function full_name(value: string) {
    return value.replace(/[^А-Яа-яё_ -]/g, '')
        .replace(/^[^А-Яа-яё]/g, '')
        .replace(/([^А-Яа-яё])+/g, '$1')
}

export const INTEGER_MSG = 'Доступны только цифры'

export function integer(value: string) {
    return value ? value.replace(/\D/g, '') : null
}

export function positiveInt(value: any, def = 1) {
    const number = ('' + value).replace(/\D/g, '')
    let a = parseInt(number)
    a = isNaN(a) ? def : a
    return a
}