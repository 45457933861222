import {ref} from '@vue/reactivity'
import {ResponseData, Search, Sorting} from '@/services/types'
import {AxiosError} from 'axios'
import {showError} from '@/composables/utils'
import {LimitPageDefault} from '@/composables/constants'
import {OrderByVariants} from '@/services/constants'
import AgentsService, {GetListParams as Params} from '@/services/AgentsService'
import {Agent} from '@/composables/agents/agents-models'
import {Ref, UnwrapRef} from 'vue'
import {positiveInt} from '@/composables/mask'


export type Meta = {
    limit: number,
    offset: number,
    sort_by: string,
    order_by: OrderByVariants,
    total: number,
    page: {
        current: number,
        total: number,
    },
}

enum SortByVariants {
    CREATED_AT = 'registration_date',
    FIRSTNAME = 'firstname',
    LASTNAME = 'lastname',
}

const SortingVariants: Sorting[] = [
    {
        name: 'Сначала новые',
        sort_by: SortByVariants.CREATED_AT,
        order_by: OrderByVariants.DESC,
    },
    {
        name: 'Сначала старые',
        sort_by: SortByVariants.CREATED_AT,
        order_by: OrderByVariants.ASC,
    },
    {
        name: 'По алфавиту фамилии',
        sort_by: SortByVariants.LASTNAME,
        order_by: OrderByVariants.ASC,
    },
    {
        name: 'Против алфавита фамилии',
        sort_by: SortByVariants.LASTNAME,
        order_by: OrderByVariants.DESC,
    },
    {
        name: 'По алфавиту имени',
        sort_by: SortByVariants.FIRSTNAME,
        order_by: OrderByVariants.ASC,
    },
    {
        name: 'Против алфавита имени',
        sort_by: SortByVariants.FIRSTNAME,
        order_by: OrderByVariants.DESC,
    }
]

export const SortingDefault: Sorting = SortingVariants[0]

enum SearchName {
    FIO = 'ФИО',
    EMAIL = 'Почта',
    REFURL = 'Реф.метка',
    PROMOCODE = 'Промокод',
    PHONE = 'Телефон',
    INN = 'ИНН',
}

enum SearchParam {
    FIO = 'search_fio',
    EMAIL = 'search_email',
    REFURL = 'search_ref',
    PROMOCODE = 'search_promo',
    PHONE = 'search_phone',
    INN = 'search_inn',
}

const SearchVariants: Search[] = [
    {
        name: SearchName.FIO,
        param: SearchParam.FIO,
        value: null,
    },
    {
        name: SearchName.EMAIL,
        param: SearchParam.EMAIL,
        value: null,
    },
    {
        name: SearchName.REFURL,
        param: SearchParam.REFURL,
        value: null,
    },
    {
        name: SearchName.PROMOCODE,
        param: SearchParam.PROMOCODE,
        value: null,
    },
    {
        name: SearchName.PHONE,
        param: SearchParam.PHONE,
        value: null,
    },
    {
        name: SearchName.INN,
        param: SearchParam.INN,
        value: null,
    }
]

export const SearchDefault: Search = SearchVariants[0]


const MetaDefault = {
    limit: LimitPageDefault.value,
    offset: 0,
    order_by: SortingDefault.order_by,
    sort_by: SortingDefault.sort_by,
    total: 0,
    page: {
        current: 1,
        total: 1,
    }
} as Meta


export interface AgentListInterface {
    searchPromo: Ref<any | undefined>
    setFilterPassportVerifyPotential: (value?: (boolean | null)) => void
    setSearchPromo: (value?: (string | null)) => void
    filterPassportVerifyPotential: Ref<any | undefined>
    search: Ref<UnwrapRef<Search>>
    setSorting: (value: Sorting) => void
    filterIsWatched: Ref<any | undefined>
    sorting: Ref<UnwrapRef<Sorting>>
    setFilterPassportVerified: (value?: (boolean | null)) => void
    setSearchPhone: (value?: (string | null)) => void
    searchVariants: Search[]
    setFilterIsWatched: (value?: (boolean | null)) => void
    searchInn: Ref<any | undefined>
    setPage: (value: number) => void
    setSearchInn: (value?: (string | null)) => void
    setFilterIsEmployer: (value?: (boolean | null)) => void
    setFilterRoleId: (value?: (number | null)) => void
    sortingVariants: Sorting[]
    filterRoleId: Ref<any | undefined>
    searchPhone: Ref<any | undefined>
    filterPassportVerified: Ref<any | undefined>
    setFilterParentId: (value?: (number | null)) => void
    list: Ref<UnwrapRef<{ [p: string]: Agent }>>
    filterBanned: Ref<any | undefined>
    setSearch: (value: Search) => void
    setFilterBanned: (value?: (boolean | null)) => void
    isLoading: Ref<UnwrapRef<boolean>>
    meta: Ref<UnwrapRef<Meta>>
    searchRef: Ref<any | undefined>
    setLimit: (value: number) => void
    setSearchEmail: (value?: (string | null)) => void
    setSearchRef: (value?: (string | null)) => void
    fetch: () => void
    searchFIO: Ref<any | undefined>
    reset: () => void
    filterIsEmployer: Ref<any | undefined>
    filterParentId: Ref<any | undefined>
    setSearchFio: (value?: (string | null)) => void
    searchEmail: Ref<any | undefined>
}

export function list(): AgentListInterface {
    const list = ref({} as { [key: string]: Agent })
    const isLoading = ref(false)
    const sorting = ref(SortingDefault)
    const search = ref(SearchDefault)
    const meta = ref(MetaDefault)
    const filterPassportVerified = ref()
    const filterBanned = ref()
    const filterPassportVerifyPotential = ref()
    const filterIsWatched = ref()
    const filterIsEmployer = ref()
    const filterParentId = ref()
    const filterRoleId = ref()
    const searchFIO = ref()
    const searchEmail = ref()
    const searchRef = ref()
    const searchPromo = ref()
    const searchPhone = ref()
    const searchInn = ref()

    function fetch(): void {

        let moderation = null

        if (filterPassportVerifyPotential.value) {
            moderation = filterPassportVerifyPotential.value ? '1' : '0'
        }

        const params = {
            limit: meta.value.limit,
            offset: meta.value.offset,
            order_by: meta.value.order_by,
            sort_by: meta.value.sort_by,
            has_verified_passport: filterPassportVerified.value,
            is_banned: filterBanned.value,
            is_waiting_verification: moderation,
            is_watched: filterIsWatched.value,
            is_employer: filterIsEmployer.value,
            parent_id: filterParentId.value,
            role_id: filterRoleId.value,
            search_fio: searchFIO.value,
            search_email: searchEmail.value,
            search_ref: searchRef.value,
            search_promo: searchPromo.value,
            search_phone: searchPhone.value,
            search_inn: searchInn.value,
        } as Params

        isLoading.value = true
        AgentsService.getList(params)
            .then((response: ResponseData) => {
                list.value = response.data.data.items
                meta.value = response.data.data.meta
            })
            .catch((e: AxiosError) => showError(e))
            .finally(() => isLoading.value = false)
    }

    function setPage(value: number) {
        meta.value.offset = (value - 1) * meta.value.limit
    }

    function setLimit(value: number) {
        meta.value.limit = value
    }

    function setSorting(value: Sorting) {
        sorting.value = value
        meta.value.sort_by = value.sort_by
        meta.value.order_by = value.order_by
    }

    function setSearch(value: Search) {
        search.value = value
    }


    function reset() {
        setLimit(LimitPageDefault.value)
        setPage(1)
        setSorting(SortingDefault)

        setFilterPassportVerified()
        setFilterBanned()
        setFilterPassportVerifyPotential()
        setFilterIsWatched()
        setFilterIsEmployer()
        setFilterParentId()
        setFilterRoleId()

        setSearch(SearchDefault)
        setSearchFio()
        setSearchEmail()
        setSearchRef()
        setSearchPromo()
        setSearchPhone()
        setSearchInn()
    }

    function setFilterPassportVerified(value?: null | boolean) {
        filterPassportVerified.value = value
    }

    function setFilterBanned(value?: null | boolean) {
        filterBanned.value = value
    }

    function setFilterPassportVerifyPotential(value?: null | boolean) {
        filterPassportVerifyPotential.value = value
    }

    function setFilterIsWatched(value?: null | boolean) {
        filterIsWatched.value = value
    }

    function setFilterIsEmployer(value?: null | boolean) {
        filterIsEmployer.value = value
    }

    function setFilterParentId(value?: null | number) {
        filterParentId.value = value
    }

    function setFilterRoleId(value?: null | number) {
        filterRoleId.value = value
    }

    function setSearchFio(value?: null | string) {
        searchFIO.value = value
    }

    function setSearchEmail(value?: null | string) {
        searchEmail.value = value
    }

    function setSearchRef(value?: null | string) {
        searchRef.value = value
    }

    function setSearchPromo(value?: null | string) {
        searchPromo.value = value
    }

    function setSearchPhone(value?: null | string) {
        searchPhone.value = value
    }

    function setSearchInn(value?: null | string) {
        searchInn.value = value
    }


    return {
        // getters
        list,
        isLoading,
        meta,
        sorting,
        sortingVariants: SortingVariants,
        filterPassportVerified,
        filterBanned,
        filterPassportVerifyPotential,
        filterIsWatched,
        filterIsEmployer,
        filterParentId,
        filterRoleId,
        search,
        searchVariants: SearchVariants,
        searchFIO,
        searchEmail,
        searchRef,
        searchPromo,
        searchPhone,
        searchInn,

        // mutations
        setPage,
        setLimit,
        setSorting,
        reset,
        setFilterPassportVerified,
        setFilterBanned,
        setFilterPassportVerifyPotential,
        setFilterIsWatched,
        setFilterIsEmployer,
        setFilterParentId,
        setFilterRoleId,
        setSearch,
        setSearchFio,
        setSearchEmail,
        setSearchRef,
        setSearchPromo,
        setSearchPhone,
        setSearchInn,

        // actions
        fetch
    }
}
