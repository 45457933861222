
import {Options, setup, Vue} from 'vue-class-component'
import {LimitPageDefault, LimitPageVariants} from '@/composables/constants'
import {list, SearchDefault, SortingDefault} from '@/composables/agents/list'
import {Search, Sorting} from '@/services/types'
import {Roles} from '@/composables/agents/types'
import {positiveInt} from '@/composables/mask'

@Options({
  components: {
  },
})
export default class List extends Vue {
  showOptions = false
  page = 1
  limit = LimitPageDefault.value
  sorting: Sorting = SortingDefault

  roles = Roles

  filterPassportVerified: null | boolean = null
  filterBanned: null | boolean = null
  filterPassportVerifyPotential: null | boolean = null
  filterIsWatched: null | boolean = null
  filterIsEmployer: null | boolean = null
  filterRoleId: null | number = null
  search: Search = SearchDefault
  searchFIO: null | string = null
  searchEmail: null | string = null
  searchRef: null | string = null
  searchPromo: null | string = null
  searchPhone: null | string = null
  searchInn: null | string = null


  created(): void {
    this.clear()
  }

  ctx = setup(() => {
    return {...list(), LimitPageVariants}
  })


  changeSearch() {
    this.searchFIO = null
    this.searchEmail = null
    this.searchRef = null
    this.searchPromo = null
    this.searchPhone = null
    this.searchInn = null
  }


  /**
   * Общая часть
   */

  // триггер на обновление таблицы для новой страницы
  async changePage(): Promise<void> {
    this.page = positiveInt(this.page)
    await this.fetch()
  }

  // Применить фильтры
  async apply() {
    this.page = 1
    await this.fetch()
  }

  // Сбросить фильтры до дефолта
  async clear() {
    this.ctx.reset()
    this.cancel()
    await this.fetch()
  }

  // Отменить введенные фильтры
  cancel() {
    this.page = this.ctx.meta.offset / this.ctx.meta.limit + 1
    this.limit = this.ctx.meta.limit
    this.sorting = this.ctx.sorting

    this.filterPassportVerified = this.ctx.filterPassportVerified ?? ''
    this.filterBanned = this.ctx.filterBanned ?? ''
    this.filterPassportVerifyPotential = this.ctx.filterPassportVerifyPotential ?? ''
    this.filterIsWatched = this.ctx.filterIsWatched ?? ''
    this.filterIsEmployer = this.ctx.filterIsEmployer ?? ''
    this.filterRoleId = this.ctx.filterRoleId ?? ''
    this.search = this.ctx.search
    this.searchFIO = this.ctx.searchFIO
    this.searchEmail = this.ctx.searchEmail
    this.searchRef = this.ctx.searchRef
    this.searchPromo = this.ctx.searchPromo
    this.searchPhone = this.ctx.searchPhone
    this.searchInn = this.ctx.searchInn

    this.close()
  }

  close() {
    this.showOptions = false
  }

  scroll(){
    document.getElementById('table')?.scrollTo({top: 0, behavior: 'smooth'})
  }

  async fetch(){

    this.ctx.setLimit(this.limit)
    this.ctx.setPage(this.page)
    this.ctx.setSorting(this.sorting)

    this.ctx.setFilterPassportVerified(this.filterPassportVerified)
    this.ctx.setFilterBanned(this.filterBanned)
    this.ctx.setFilterPassportVerifyPotential(this.filterPassportVerifyPotential)
    this.ctx.setFilterIsWatched(this.filterIsWatched)
    this.ctx.setFilterIsEmployer(this.filterIsEmployer)
    this.ctx.setFilterRoleId(this.filterRoleId)
    this.ctx.setSearch(this.search)
    this.ctx.setSearchFio(this.searchFIO)
    this.ctx.setSearchEmail(this.searchEmail)
    this.ctx.setSearchRef(this.searchRef)
    this.ctx.setSearchPromo(this.searchPromo)
    this.ctx.setSearchPhone(this.searchPhone)
    this.ctx.setSearchInn(this.searchInn)

    await this.ctx.fetch()
    this.close()
    this.scroll()
  }
}
