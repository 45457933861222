enum RoleId {
    AGENT = 1,
    DUMMY_AGENT = 2,
    HUNTER = 3,
    CLOSER = 4,
    KAZ_CLIENT = 5,
}

type Role = {
    id: RoleId,
    name: string
}

export const Roles: Role[] = [
    {
        id: RoleId.AGENT,
        name: 'Саморег'
    },
    {
        id: RoleId.DUMMY_AGENT,
        name: 'Спец. рег'
    },
    {
        id: RoleId.HUNTER,
        name: 'Хантер'
    },
    {
        id: RoleId.CLOSER,
        name: 'Клозер'
    },
    {
        id: RoleId.KAZ_CLIENT,
        name: 'Миграция из казармы'
    },
]